export default {
  path: '/enwechat',
  name: 'enwechat',
  component: () => import('@/layouts/WholeLayout'),
  redirect: '/enwechat/externalControl',
  children: [
    {
      path: 'externalcontrol',
      meta: { title: '数据', permission: 'externalcontrol', public: true},
      component: () => import('@/views/enwechat/externalControl')
    },
  ],
}