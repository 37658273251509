import functions from "@/utils/functions";
import VueRouter from "vue-router";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import Vue from "vue";
import Viser from "viser-vue";
import Antd from "ant-design-vue/es";
import Print from "vue-print-nb";
import htmlToPdf from "@/utils/htmlToPdf";
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false;
Vue.prototype.$functions = functions;
Vue.use(VueRouter);
Vue.use(Viser);
Vue.use(Antd);
Vue.use(Print);
Vue.use(htmlToPdf);


// 导航守卫
// router.beforeEach((to, from, next) => {
//   // 检查用户是否登录
//   const isAuthenticated = store.getters.isAuthenticated;  // 使用 Vuex 的 getter 检查登录状态
//
//   // 如果用户访问的是不需要登录的页面，或者用户已登录，则允许访问
//   if (to.meta.public || isAuthenticated) {
//     next();
//   } else {
//     // 否则，重定向到登录页面
//     next("/user/login");
//   }
// });


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
